import React from 'react';

const ResetPasswordForm = () => {

  return (
    <>
      <div>Reset Password Comming Soon!!!</div>
    </>
  )
}

export default ResetPasswordForm;

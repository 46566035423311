import t from '../common/localization'

export default {
  'notificationTokens': {
    name: t('attributeNotificationTokens'),
    type: 'string',
  },
  'web.liveRouteLength': {
    name: t('attributeWebLiveRouteLength'),
    type: 'number',
  },
  'web.selectZoom': {
    name: t('attributeWebSelectZoom'),
    type: 'number',
  },
  'web.maxZoom': {
    name: t('attributeWebMaxZoom'),
    type: 'number',
  },
  'ui.disableReport': {
    name: t('attributeUiDisableReport'),
    type: 'boolean',
  },
  'ui.disableEvents': {
    name: t('attributeUiDisableEvents'),
    type: 'boolean',
  },
  'ui.disableVehicleFetures': {
    name: t('attributeUiDisableVehicleFetures'),
    type: 'boolean',
  },
  'ui.disableDrivers': {
    name: t('attributeUiDisableDrivers'),
    type: 'boolean',
  },
  'ui.disableComputedAttributes': {
    name: t('attributeUiDisableComputedAttributes'),
    type: 'boolean',
  },
  'ui.disableCalendars': {
    name: t('attributeUiDisableCalendars'),
    type: 'boolean',
  },
  'ui.disableMaintenance': {
    name: t('attributeUiDisableMaintenance'),
    type: 'boolean',
  },
  'ui.hidePositionAttributes': {
    name: t('attributeUiHidePositionAttributes'),
    type: 'string',
  },
  'distanceUnit': {
    name: t('settingsDistanceUnit'),
    type: 'string',
  },
  'speedUnit': {
    name: t('settingsSpeedUnit'),
    type: 'string',
  },
  'volumeUnit': {
    name: t('settingsVolumeUnit'),
    type: 'string',
  },
  'timezone': {
    name: t('sharedTimezone'),
    type: 'string',
  },
};
